import React, {useEffect} from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet"
import Logo from "../../static/chode-dynasty-logo.svg"
import { FaChevronDown, FaBars } from 'react-icons/fa'

function Page() {
  useEffect(() => {
    const mainNav = document.querySelector('#main-nav');
    const mainNavTrigger = document.querySelector('#nav-trigger');
    const navUpdatesTrigger = document.querySelector('#subnav-updates-trigger');
    const navRecapTrigger = document.querySelector('#subnav-recap-trigger');
    
    mainNavTrigger.onclick = () => mainNav.classList.toggle('active');
    navUpdatesTrigger.onclick = () => navUpdatesTrigger.classList.toggle('active');
    navRecapTrigger.onclick = () => navRecapTrigger.classList.toggle('active');

    window.onclick = function(event) {
      if (!event.target.matches('#subnav-updates-trigger')) {
        navUpdatesTrigger.classList.remove('active');
      }

      if (!event.target.matches('#subnav-recap-trigger')) {
        navRecapTrigger.classList.remove('active');
      }
    }
  })

  return (
    <React.Fragment>
      <Helmet>    
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <header className="row">
        <div className="inner">
          <div className="top">
            <img src={Logo} alt="Chode Dynasty logo" id="logo" />
            <div className="site-title">Chode Dynasty</div>
          </div>

          <button id="nav-trigger"><FaBars /> Menu</button>

          <nav id="main-nav">
            <ul>
              <li><Link to="/" activeClassName="active">Constitution</Link></li>
              <li><Link to="/history" activeClassName="active">History</Link></li>

              <li>
                <button id="subnav-updates-trigger" className="level">Commish Updates <span className="arrow"><FaChevronDown /></span></button>

                <ul>
                  <li><Link to="/updates/2024" activeClassName="active">2024</Link></li>
                  <li><Link to="/updates/2023" activeClassName="active">2023</Link></li>
                  <li><Link to="/updates/2022" activeClassName="active">2022</Link></li>
                  <li><Link to="/updates/2021" activeClassName="active">2021</Link></li>
                  <li><Link to="/updates/2020" activeClassName="active">2020</Link></li>
                  <li><Link to="/updates/2019" activeClassName="active">2019</Link></li>
                </ul>
              </li>

              <li>
                <button id="subnav-recap-trigger" className="level">Season Recaps <span className="arrow"><FaChevronDown /></span></button>

                <ul>
                  <li><Link to="/recaps/2023" activeClassName="active">2023</Link></li>
                  <li><Link to="/recaps/2022" activeClassName="active">2022</Link></li>
                  <li><Link to="/recaps/2021" activeClassName="active">2021</Link></li>
                  <li><Link to="/recaps/2020" activeClassName="active">2020</Link></li>
                  <li><Link to="/recaps/2019" activeClassName="active">2019</Link></li>
                  <li><Link to="/recaps/2018" activeClassName="active">2018</Link></li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Page;